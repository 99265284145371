import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta:{
      title: 'الرئيسية'
    },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/statistics",
    name: "statistics",
    meta:{
      title: 'الإحصائيات'
    },
    component: () => import("./views/management-stats/Index.vue"),
  },
  {
    path: "/statistics/clients",
    name: "clients-stats",
    meta:{
      title: 'احصائيات العملاء'
    },
    component: () => import("./views/management-stats/clients.vue"),
  },
  {
    path: "/statistics/orders",
    name: "orders-stats",
    meta:{
      title: 'احصائيات الطلبات'
    },
    component: () => import("./views/management-stats/orders.vue"),
  },
  {
    path: "/statistics/items",
    name: "items-stats",
    meta:{
      title: 'احصائيات المنتجات'
    },
    component: () => import("./views/management-stats/items.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
      title: "تسجيل الدخول"
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/dashboard/saas",
    name: "saas-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/saas/index"),
  },
  {
    path: "/dashboard/crypto",
    name: "crypto-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/crypto/index"),
  },
  {
    path: "/dashboard/blog",
    name: "blog-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/blog/index"),
  },
  {
    path: "/dashboard/jobs",
    name: "jobs-dashboard",
    meta: { authRequired: true },
    component: () => import("./views/dashboards/jobs/index"),
  },
  {
    path: "/calendar",
    name: "Calendar",
    meta: { authRequired: true },
    component: () => import("./views/calendar/calendar"),
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("./views/chat/index"),
    meta:{
      title: 'الدردشة'
    }
  },
  {
    path: "/apps/file-manager",
    name: "file-manager",
    meta: { authRequired: true },
    component: () => import("./views/file-manager/index"),
  },
  {
    path: "/ecommerce/products",
    name: "Products",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail/:id",
    name: "Product Detail",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/product-detail"),
  },
  {
    path: "/ecommerce/orders",
    name: "Orders",
    meta: { authRequired: true },
    component: () => import("./views/ecommerce/orders"),
  },
  {
    path: "/blog/list",
    name: "blog-list",
    meta: { authRequired: true },
    component: () => import("./views/blog/list"),
  },
  {
    path: "/blog/grid",
    name: "blog-grid",
    meta: { authRequired: true },
    component: () => import("./views/blog/grid"),
  },
  {
    path: "/blog/detail",
    name: "blog-detail",
    meta: { authRequired: true },
    component: () => import("./views/blog/detail"),
  },
  {
    path: "/jobs/candidate/list",
    name: "candidate-list",
    meta: { authRequired: true },
    component: () => import("./views/jobs/candidate/list"),
  },
  {
    path: "/jobs/candidate/overview",
    name: "candidate-overview",
    meta: { authRequired: true },
    component: () => import("./views/jobs/candidate/overview"),
  },
  {
    path: "/jobs/job-categories",
    name: "job-categories",
    meta: { authRequired: true },
    component: () => import("./views/jobs/job-categories"),
  },
  {
    path: "/jobs/job-details",
    name: "job-details",
    meta: { authRequired: true },
    component: () => import("./views/jobs/job-details"),
  },
  {
    path: "/jobs/job-apply",
    name: "job-apply",
    meta: { authRequired: true },
    component: () => import("./views/jobs/job-apply"),
  },
  {
    path: "/jobs/job-grid",
    name: "job-grid",
    meta: { authRequired: true },
    component: () => import("./views/jobs/job-grid"),
  },
  {
    path: "/jobs/job-list",
    name: "job-list",
    meta: { authRequired: true },
    component: () => import("./views/jobs/job-list"),
  },
  {
    path: "/items",
    name: "المنتجات",
    meta:{
      title: 'المنتجات'
    },
    component: () => import("./views/items/Index"),

  },
  {
    path: "/items/create",
    name: "اضافة منتج",
    meta:{
      title: 'اضافة منتج'
    },
    component: () => import("./views/items/Create"),
  },
  {
    path: "/items/:id/edit",
    name: "تعديل منتج",
    meta:{
      title: 'تعديل المنتج'
    },
    component: () => import("./views/items/Edit"),
  },
  {
    path: "/items/:id/show",
    name: "تفاصيل المنتج",
    meta:{
      title: 'تفاصيل المنتج'
    },
    component: () => import("./views/items/Show"),
  },
  //charities
  {
    path: "/charities",
    name: "الجمعيات",
    meta:{
      title: 'الجمعيات'
    },
    component: () => import("./views/charities/Index"),
  },
  {
    path: "/charities/create",
    name: "اضافة جمعية",
    meta:{
      title: 'اضافة جمعية'
    },
    component: () => import("./views/charities/Create"),
  },
  {
    path: "/charities/:id/edit",
    name: "تعديل الجمعية",
    meta:{
      title: 'تعديل الجمعية'
    },
    component: () => import("./views/charities/Edit"),
  },
  {
    path: "/charities/:id/show",
    name: "تفاصيل الجمعية",
    component: () => import("./views/charities/Show"),
    meta:{
      title: 'تفاصيل الجمعية'
    },
  },
  // testimonials
  {
    path: "/testimonials",
    name: "ماذا قالوا عنا",
    meta:{
      title: 'أراء العملاء'
    },
    component: () => import("./views/testimonials/Index"),
  },
  {
    path: "/testimonials/create",
    name: "  إضافة أراء العملاء ",
    meta:{
      title: 'اضافة أراء العملاء'
    },
    component: () => import("./views/testimonials/Create"),
  },
  {
    path: "/testimonials/:id/edit",
    name: " تعديل أراء العملاء ",
    meta:{
      title: 'تعديل أراء العملاء'
    },
    component: () => import("./views/testimonials/Edit"),
  },
  {
    path: "/testimonials/:id/show",
    name: "تفاصيل أراء العملاء ",
    meta:{
      title: 'عرض أراء العملاء'
    },
    component: () => import("./views/testimonials/Show"),
  },
   // categories
   {
    path: "/categories",
    name: " التصنيفات",
    meta:{
      title: 'التصنيفات'
    },
    component: () => import("./views/categories/Index"),
  },
 
  {
    path: "/categories/:id/edit",
    name: " تعديل التصنيف ",
    meta:{
      title: 'تعديل التصنيف'
    },
    component: () => import("./views/categories/Edit"),
  },
     //gift
     {
      path: "/gifts",
      meta:{
        title: 'الإهداء'
      },
      component: () => import("./views/gift/Index"),
    },

    //notifications
    {
      path: "/notifications",
      name: "الإشعارات",
      meta:{
        title: 'الإشعارات'
      },
      component: () => import("./views/notifications/Index"),
    },
    {
      path: "/notifications/create",
      name: "اضافة اشعار",
      meta:{
        title: 'إضافة اشعار'
      },
      component: () => import("./views/notifications/Create"),
    },
    {
      path: "/notifications/:id/show",
      name: "تفاصيل الاشعار",
      meta:{
        title: 'تفاصيل الإشعار'
      },
      component: () => import("./views/notifications/Show"),
    },
  {
    path: "/sliders",
    name: "الشرائح",
    meta:{
      title: 'الشرائح المتحركة'
    },
    component: () => import("./views/sliders/Index"),
  },
  {
    path: "/sliders/create",
    name: "اضافة شريحة",
    meta:{
      title: 'اضافة شريحة'
    },
    component: () => import("./views/sliders/Create"),
  },
  {
    path: "/sliders/:id/edit",
    name: "تعديل شريحة",
    meta:{
      title: 'تعديل الشريحة'
    },
    component: () => import("./views/sliders/Edit"),
  },
  {
    path: "/sliders/:id/show",
    name: "تفاصيل شريحة",
    meta:{
      title: 'تفاصيل الشريحة'
    },
    component: () => import("./views/sliders/Show"),
  },
  {
    path: "/delivery-employees",
    name: " الموصلين",
    meta:{
      title: 'الموصلين'
    },
    component: () => import("./views/delivery-employees/Index"),
  },
  {
    path: "/delivery-employees/create",
    name: "اضافة  موصل",
    meta:{
      title: 'اضافة موصل'
    },
    component: () => import("./views/delivery-employees/Create"),
  },
  {
    path: "/delivery-employees/:id/edit",
    name: "تعديل  بيانات الموصل",
    meta:{
      title: 'تعديل الموصل'
    },
    component: () => import("./views/delivery-employees/Edit"),
  },
  {
    path: "/delivery-employees/:id/show",
    name: "تفاصيل  الموصل ",
    meta:{
      title: 'عرض الموصل'
    },
    component: () => import("./views/delivery-employees/Show"),
  },
  {
    path: "/roles",
    name: " الوظائف",
    meta:{
      title: 'الوظائف'
    },
    component: () => import("./views/roles/Index"),
  },
  {
    path: "/roles/create",
    name: "اضافة  وظيفة",
    meta:{
      title: 'اضافة وظيفة'
    },
    component: () => import("./views/roles/Create"),
  },
  {
    path: "/roles/:id/edit",
    name: "تعديل وظيفة",
    meta:{
      title: 'تعديل الوظيفة'
    },
    component: () => import("./views/roles/Edit"),
  },
  {
    path: "/roles/:id/show",
    name: "تفاصيل  وظيفة ",
    meta:{
      title: 'تفاصيل الوظيفة'
    },
    component: () => import("./views/roles/Show"),
  },
  {
    path: "/buying-invoices",
    name: " الفواتير",
    component: () => import("./views/buying-invoices/Index"),
    meta:{
      title: 'الفواتير'
    },
  },
  {
    path: "/buying-invoices/create",
    name: "اضافة  فاتورة ",
    meta:{
      title: 'اضافة فاتورة'
    },
    component: () => import("./views/buying-invoices/Create"),
  },
  {
    path: "/buying-invoices/:id/edit",
    name: "تعديل  بيانات الفاتورة ",
    meta:{
      title: 'تعديل الفاتورة'
    },
    component: () => import("./views/buying-invoices/Edit"),
  },
  {
    path: "/buying-invoices/:id/show",
    name: "تفاصيل  الفاتورة  ",
    meta:{
      title: 'تفاصيل الفاتورة'
    },
    component: () => import("./views/buying-invoices/Show"),
  },
  {
    path: "/stock",
    name: " المخزن",
    meta:{
      title: 'المخزن'
    },
    component: () => import("./views/stock/Index"),
  },
  {
    path: "/stock-transactions",
    name: " عمليات المخزن",
    meta:{
      title: 'عمليات المخزن'
    },
    component: () => import("./views/stock-transactions/Index"),
  },
  {
    path: "/stock-transactions/create",
    name: "  إدخال عملية",
    meta:{
      title: 'إدخال عملية'
    },
    component: () => import("./views/stock-transactions/Create"),
  },
  {
    path: "/transactions",
    name: " المعاملات", 
    meta:{
      title: 'المعاملات'
    },
    component: () => import("./views/transactions/Index"),
  },
  {
    path: "/transactions/:id/show",
    name: " عرض بيانات المعاملة",
    meta:{
      title: ' عرض بيانات المعاملة"'
    },
    component: () => import("./views/transactions/Show"),
  },
  {
    path: "/orders",
    name: " الطلبات",
    meta:{
      title: 'الطلبات'
    },
    component: () => import("./views/orders/Index"),
  },
  {
    path: "/orders/create",
    name: " إضافة طلب ",
    meta:{
      title: 'اضافة طلب'
    },
    component: () => import("./views/orders/Create"),
  },
  {
    path: "/orders/:id/show",
    name: " تفاصيل المنتج",
    meta:{
      title: 'تفاصيل الطلب'
    },
    component: () => import("./views/orders/Show"),
  },
  {
    path: "/orders/:id/edit",
    name: " تعديل المنتج",
    meta:{
      title: 'تعديل الطلب'
    },
    component: () => import("./views/orders/Edit"),
  },
  {
    path: "/verifications/new-orders",
    name: " الطلبات الجديدة",
    meta:{
      title: 'الطلبات الجديدة'
    },
    component: () => import("./views/orders-type/new-orders"),
  },
  {
    path: "/verifications/upcoming-orders",
    name: " الطلبات جارية التوصيل",
    meta:{
      title: 'الطلبات جارية التوصيل'
    },
    component: () => import("./views/orders-type/upcoming-orders"),
  },
  {
    path: "/verifications/waiting-approval",
    name: "  انتظار قبول التوثيق)الطلبات الموثقة )",
    meta:{
      title:"  انتظار قبول التوثيق)الطلبات الموثقة )",
    },
    component: () => import("./views/orders-type/waiting-approval"),
  },
  {
    path: "/verifications/approved-orders",
    name: "  الطلبات الموثقة",
    meta:{
      title: 'الطلبات الموثقة'
    },
    component: () => import("./views/orders-type/approved-orders"),
  },
  {
    path: "/verifications/approved-no-verification",
    name: "  طلبات تم تأكيد توثيقها (ولا يوجد توثيق) ",
    meta:{
      title: "طلبات تم تأكيد توثيقها (ولا يوجد توثيق) "
    },
    component: () => import("./views/orders-type/approved-no-verification"),
  },
  {
    path: "/verifications/completed-no-verification",
    name: "  طلبات تم  توصيلها (ولا يوجد توثيق) ",
    meta:{
      title: "طلبات تم  توصيلها (ولا يوجد توثيق) "
    },
    component: () => import("./views/orders-type/completed-no-verification"),
  },
  {
    path: "/verifications/rejected-orders",
    name: " الطلبات المرفوضة",
    meta:{
      title: 'الطلبات المرفوضة'
    },
    component: () => import("./views/orders-type/rejected-orders"),
  },
  {
    path: "/verifications/statistics",
    name: " احصائيات التوثيق",
    meta:{
      title: "احصائيات التوثيق"
    },
    component: () => import("./views/orders-type/verification-statistics"),
  },

  {
    path: "/reports/meals",
    name: "meals-report",
    meta: {
      title: 'تقرير الوجبات'
    },
    component: () => import("./views/reports/meals"),
  },
  {
    path: "/reports/wallet",
    name: "wallet-report",
    meta: {
      title: 'تقرير محافظ العملاء'
    },
    component: () => import("./views/reports/wallet"),
  },
  {
    path: "/reports/stock",
    name: "stock-report",
    meta: {
      title: 'تقرير  المخزن'
    },
    component: () => import("./views/reports/stock"),
  },

  {
    path: "/marketing/orders",
    name: " طلبات المسوق",
    meta:{
      title: 'طلبات التسويق'
    },
    component: () => import("./views/marketing/Index"),

  },
  {
    path: "/marketing/stats",
    name: "  احصائيات العملاء الجدد",
    meta:{
      title: 'احصائيات العملاء الجدد'
    },
    component: () => import("./views/marketing/new-orders-stats"),

  },
  {
    path: "/verification/:id/edit",
    name: "  التوثيق",
    meta:{
      title: 'تعديل التوثيق'
    },
    component: () => import("./views/verification/Edit"),
  },
  {
    path: "/mosques",
    name: "المساجد",
    meta:{
      title: 'المساجد'
    },
    component: () => import("./views/mosques/Index"),
  },
  {
    path: "/mosques/create",
    name: "اضافة مسجد",
    meta:{
      title: 'اضافة مسجد'
    },
    component: () => import("./views/mosques/Create"),
  },
  {
    path: "/mosques/:id/edit",
    name: "تعديل مسجد",
    meta:{
      title: 'تعديل مسجد'
    },
    component: () => import("./views/mosques/Edit"),
  },
  {
    path: "/mosques/:id/show",
    name: "تفاصيل المسجد ",
    meta:{
      title: 'تفاصيل المسجد'
    },
    component: () => import("./views/mosques/Show"),
  },
  {
    path: "/users",
    name: "المستخدمين",
    meta:{
      title: 'مستخدمى النظام'
    },
    component: () => import("./views/users/Index"),
  },
  {
    path: "/users/create",
    name: "اضافة مستخدم",
    meta:{
      title: 'اضافة مستخدم'
    },
    component: () => import("./views/users/Create"),
  },
  {
    path: "/users/:id/edit",
    name: "تعديل مستخدم",
    meta:{
      title: 'تعديل مستخدم'
    },
    component: () => import("./views/users/Edit"),
  },
  {
    path: "/users/:id/show",
    name: "تفاصيل المستخدم ",
    meta:{
      title: 'تفاصيل المستخدم'
    },
    component: () => import("./views/users/Show"),
  },
  //contacts
{
  path: "/contacts",
  name: "أرقام التواصل",
  meta:{
    title: 'أرقام التواصل'
  },
  component: () => import("./views/contacts-numbers/Index"),
},
{
  path: "/contacts/create",
  name: "اضافة ",
  meta:{
    title: 'اضافة رقم تواصل'
  },
  component: () => import("./views/contacts-numbers/Create"),
},
{
  path: "/contacts/:id/edit",
  name: "تعديل الرقم",
  meta:{
    title: 'تعديل رقم التواصل'
  },
  component: () => import("./views/contacts-numbers/Edit"),
},
{
  path: "/account-number",
  name: " رقم الحساب",
  meta: { authRequired: true },
  component: () => import("./views/delete-account/Create"),
},
{
  path: "/verify-otp",
  name: "  رمز التأكيد",
  meta: { authRequired: true },
  component: () => import("./views/delete-account/verify-otp"),
},
{
  path: "/delete-account",
  name: "  حذف الحساب",
  meta: { authRequired: true },
  component: () => import("./views/delete-account/Delete"),
},
  //parent items
  {
    path: "/parent-items",
    name: " قائمة المنتجات ",
    meta:{
      title: 'المنتجات الرئيسية'
    },
    component: () => import("./views/parent-items/Index"),
  },
  {
    path: "/parent-items/create",
    name: "اضافة المنتج الأب ",
    meta:{
      title: ' اضافة منتج رئيسى'
    },
    component: () => import("./views/parent-items/Create"),
  },
  {
    path: "/parent-items/:id/edit",
    name: "تعديل المنتج الأب",
    meta:{
      title: 'تعديل المنتج الرئيسى'
    },
    component: () => import("./views/parent-items/Edit"),
  },

  {
    path: "/clients",
    name: "العملاء",
    meta:{
      title: 'مستخدمى التطبيق'
    },
    component: () => import("./views/clients/Index"),
  },
  {
    path: "/subscriptions",
    name: "اشتراكات العملاء",
    meta:{
      title: 'اشتراكات العملاء'
    },
    component: () => import("./views/subscriptions/Index"),
  },
  {
    path: "/subscriptions/:id/show",
    name: "تفاصيل الإشتراك",
    meta:{
      title: ' تفاصيل الإشتراك'
    },
    component: () => import("./views/subscriptions/Show"),
  },
  {
    path: "/advance-subscriptions",
    name: "اشتراكات الدفع المقدم",
    meta:{
      title: 'اشتراكات الدفع المقدم'
    },
    component: () => import("./views/advance-subscriptions/Index"),
  },
  {
    path: "/advance-subscriptions/:id/show",
    name: "تفاصيل اشتراك الدفع المقدم",
    meta:{
      title: ' تفاصيل اشتراك الدفع المقدم'
    },
    component: () => import("./views/advance-subscriptions/Show"),
  },
  {
    path: "/invoice",
    name: "الفاتورة الإلكترونية",
    meta:{
      title: 'الفاتورة الإلكترونية'
    },
    component: () => import("./views/invoice/Index"),
  },
  {
    path: "/test",
    name: "الفاتورة ",
    meta: { authRequired: true },
    component: () => import("./views/invoice/Test"),
  },
  {
    path: "/video",
    name: "video",
    meta: { authRequired: true },
    component: () => import("./views/video/Index"),
  },
  {
    path: "/settings",
    name: "الإعدادات",
    meta:{
      title: 'الإعدادات'
    },
    component: () => import("./views/settings/Create"),
  },
  {
    path: "/crypto/wallet",
    name: "Wallet",
    meta: { authRequired: true },
    component: () => import("./views/crypto/wallet/index"),
  },
  {
    path: "/crypto/buy-sell",
    name: "Buy/sell",
    meta: { authRequired: true },
    component: () => import("./views/crypto/buysell/index"),
  },
  {
    path: "/crypto/exchange",
    name: "Exchange",
    meta: { authRequired: true },
    component: () => import("./views/crypto/exchange/index"),
  },
  {
    path: "/crypto/lending",
    name: "Lending",
    meta: { authRequired: true },
    component: () => import("./views/crypto/lending/index"),
  },
  {
    path: "/crypto/orders",
    name: "crypto-orders",
    meta: { authRequired: true },
    component: () => import("./views/crypto/orders/index"),
  },
  {
    path: "/crypto/kyc-application",
    name: "kyc-application",
    meta: { authRequired: true },
    component: () => import("./views/crypto/kycapplication/index"),
  },
  {
    path: "/crypto/ico-landing",
    name: "Ico-landing",
    meta: { authRequired: true },
    component: () => import("./views/crypto/ico-landing"),
  },
  // {
  //   path: "/invoices/detail",
  //   name: "Invoice Detail",
  //   meta: { authRequired: true },
  //   component: () => import("./views/invoices/detail"),
  // },
  // {
  //   path: "/invoices/list",
  //   name: "Invoice List",
  //   meta: { authRequired: true },
  //   component: () => import("./views/invoices/list"),
  // },
  {
    path: "/ui/alerts",
    name: "Alerts",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "Buttons",
    meta: {
      authRequired: true,
    },
    component: () => import("./views/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "Cards",
    meta: { authRequired: true },
    component: () => import("./views/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "Carousel",
    meta: { authRequired: true },
    component: () => import("./views/ui/carousel"),
  },
  {
    path: "/ui/dropdowns",
    name: "Dropdowns",
    meta: { authRequired: true },
    component: () => import("./views/ui/dropdowns"),
  },
  {
    path: "/ui/grid",
    name: "Grid",
    meta: { authRequired: true },
    component: () => import("./views/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "Images",
    meta: { authRequired: true },
    component: () => import("./views/ui/images"),
  },
  {
    path: "/ui/modals",
    name: "Modals",
    meta: { authRequired: true },
    component: () => import("./views/ui/modals"),
  },
  {
    path: "/ui/offcanvas",
    name: "offcanvas",
    meta: { authRequired: true },
    component: () => import("./views/ui/offcanvas"),
  },
  {
    path: "/ui/rangeslider",
    name: "Rangeslider",
    meta: { authRequired: true },
    component: () => import("./views/ui/rangeslider"),
  },
  {
    path: "/ui/progressbars",
    name: "Progressbars",
    meta: { authRequired: true },
    component: () => import("./views/ui/progressbars"),
  },
  {
    path: "/ui/placeholder",
    name: "Placeholder",
    meta: { authRequired: true },
    component: () => import("./views/ui/placeholder"),
  },
  {
    path: "/ui/sweet-alert",
    name: "Sweet-alert",
    meta: { authRequired: true },
    component: () => import("./views/ui/sweet-alert"),
  },
  {
    path: "/ui/tabs-accordions",
    name: "Tabs-accordions",
    meta: { authRequired: true },
    component: () => import("./views/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "Typography",
    meta: { authRequired: true },
    component: () => import("./views/ui/typography"),
  },
  {
    path: "/ui/video",
    name: "Video",
    meta: { authRequired: true },
    component: () => import("./views/ui/video"),
  },
  {
    path: "/ui/general",
    name: "General",
    meta: { authRequired: true },
    component: () => import("./views/ui/general"),
  },
  {
    path: "/ui/colors",
    name: "Colors",
    meta: { authRequired: true },
    component: () => import("./views/ui/colors"),
  },
  {
    path: "/ui/lightbox",
    name: "Lightbox",
    meta: { authRequired: true },
    component: () => import("./views/ui/lightbox"),
  },
  {
    path: "/ui/image-cropper",
    name: "Image Cropper",
    meta: { authRequired: true },
    component: () => import("./views/ui/cropper"),
  },
  {
    path: "/ui/utilities",
    name: "Utilities",
    meta: { authRequired: true },
    component: () => import("./views/ui/utilities"),
  },
  {
    path: "/projects/grid",
    name: "Projects Grid",
    meta: { authRequired: true },
    component: () => import("./views/projects/projects-grid"),
  },
  {
    path: "/projects/list",
    name: "Projects List",
    meta: { authRequired: true },
    component: () => import("./views/projects/projects-list"),
  },
  {
    path: "/projects/overview",
    name: "Project Overview",
    meta: { authRequired: true },
    component: () => import("./views/projects/overview"),
  },
  {
    path: "/projects/create",
    name: "Create New",
    meta: { authRequired: true },
    component: () => import("./views/projects/create"),
  },
  {
    path: "/contacts/grid",
    name: "User Grid",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-grid"),
  },
  {
    path: "/contacts/list",
    name: "User List",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-list"),
  },
  {
    path: "/contacts/profile",
    name: "Profile",
    meta: { authRequired: true },
    component: () => import("./views/contacts/contacts-profile"),
  },
  {
    path: "/charts/apex",
    name: "Apex chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/apex"),
  },
  {
    path: "/charts/test",
    name: "test chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/charts-test"),
  },

  {
    path: "/charts/chartjs",
    name: "Chartjs chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/chartjs/index"),
  },
  {
    path: "/charts/chartist",
    name: "Chartist chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/chartist"),
  },
  {
    path: "/charts/echart",
    name: "Echart chart",
    meta: { authRequired: true },
    component: () => import("./views/charts/echart/index"),
  },
  {
    path: "/icons/boxicons",
    name: "Boxicons Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/boxicons"),
  },
  {
    path: "/icons/materialdesign",
    name: "Materialdesign Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/materialdesign"),
  },
  {
    path: "/icons/dripicons",
    name: "Dripicons Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/dripicons"),
  },
  {
    path: "/icons/fontawesome",
    name: "Fontawesome Icon",
    meta: { authRequired: true },
    component: () => import("./views/icons/fontawesome"),
  },
  {
    path: "/maps/google",
    name: "Google Maps",
    meta: { authRequired: true },
    component: () => import("./views/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "Leaflet Maps",
    meta: { authRequired: true },
    component: () => import("./views/maps/leaflet/index"),
  },
  {
    path: "/tables/basic",
    name: "Basic Tables",
    meta: { authRequired: true },
    component: () => import("./views/tables/basictable"),
  },
  {
    path: "/form/advanced",
    name: "Form Advanced",
    meta: { authRequired: true },
    component: () => import("./views/forms/advanced"),
  },
  {
    path: "/form/elements",
    name: "Form Elements",
    meta: { authRequired: true },
    component: () => import("./views/forms/elements"),
  },
  {
    path: "/form/layouts",
    name: "Form Layouts",
    meta: { authRequired: true },
    component: () => import("./views/forms/layouts"),
  },
  {
    path: "/form/editor",
    name: "Form Editors",
    meta: { authRequired: true },
    component: () => import("./views/forms/editors"),
  },
  {
    path: "/form/uploads",
    name: "File Uploads",
    meta: { authRequired: true },
    component: () => import("./views/forms/uploads"),
  },
  {
    path: "/form/validation",
    name: "Form Validation",
    meta: { authRequired: true },
    component: () => import("./views/forms/validation"),
  },
  {
    path: "/form/wizard",
    name: "Form Wizard",
    meta: { authRequired: true },
    component: () => import("./views/forms/wizard"),
  },
  {
    path: "/form/repeater",
    name: "Form Repeater",
    meta: { authRequired: true },
    component: () => import("./views/forms/repeater"),
  },
  {
    path: "/form/mask",
    name: "Form Mask",
    meta: { authRequired: true },
    component: () => import("./views/forms/mask"),
  },
  {
    path: "/pages/starter",
    name: "Starter",
    meta: { authRequired: true },
    component: () => import("./views/utility/starter"),
  },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { authRequired: true },
    component: () => import("./views/utility/maintenance"),
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { authRequired: true },
    component: () => import("./views/utility/coming-soon"),
  },
  {
    path: "/pages/timeline",
    name: "Timeline",
    meta: { authRequired: true },
    component: () => import("./views/utility/timeline"),
  },
  {
    path: "/pages/faqs",
    name: "FAQs",
    meta: { authRequired: true },
    component: () => import("./views/utility/faqs"),
  },
  {
    path: "/pages/pricing",
    name: "Pricing",
    meta: { authRequired: true },
    component: () => import("./views/utility/pricing"),
  },
  {
    path: "/:catchAll(.*)",
    name: "Error-404",
    meta: { authRequired: true },
    component: () => import("./views/utility/404"),
  },
  {
    path: "/pages/500",
    name: "Error-500",
    meta: { authRequired: true },
    component: () => import("./views/utility/500"),
  },
  {
    path: "/email/inbox",
    name: "Inbox",
    meta: { authRequired: true },
    component: () => import("./views/email/inbox"),
  },
  {
    path: "/email/reademail/:id",
    name: "Read Email",
    meta: { authRequired: true },
    component: () => import("./views/email/reademail"),
  },
  {
    path: "/email/templates/basic",
    name: "Email template basic",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/basic"),
  },
  {
    path: "/email/templates/billing",
    name: "Email template billing",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/billing"),
  },
  {
    path: "/email/templates/alert",
    name: "Email template alert",
    meta: { authRequired: true },
    component: () => import("./views/email/templates/alert"),
  },
  {
    path: "/tasks/list",
    name: "Task list",
    meta: { authRequired: true },
    component: () => import("./views/tasks/task-list"),
  },
  {
    path: "/tasks/kanban",
    name: "Kanbanboard",
    meta: { authRequired: true },
    component: () => import("./views/tasks/kanbanboard"),
  },
  {
    path: "/tasks/create",
    name: "Create Task",
    meta: { authRequired: true },
    component: () => import("./views/tasks/task-create"),
  },
  {
    path: "/auth/login-1",
    name: "Login sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/login-sample"),
  },
  {
    path: "/auth/login-2",
    name: "Login-2-sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/login-2"),
  },
  {
    path: "/auth/register-1",
    name: "Register sample",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/register-sample"),
  },
  {
    path: "/auth/register-2",
    name: "Register-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/register-2"),
  },
  {
    path: "/auth/recoverpwd",
    name: "Recover pwd",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/recoverpw-sample"),
  },
  {
    path: "/auth/recoverpwd-2",
    name: "Recover pwd-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/recoverpwd-2"),
  },
  {
    path: "/auth/lock-screen",
    name: "Lock screen",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/lockscreen"),
  },
  {
    path: "/auth/lock-screen-2",
    name: "Lock screen-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/lockscreen-2"),
  },
  {
    path: "/auth/confirm-mail",
    name: "confirm-mail",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/confirm-mail"),
  },
  {
    path: "/auth/confirm-mail-2",
    name: "confirm-mail-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/confirm-mail-2"),
  },
  {
    path: "/auth/email-verification",
    name: "email-verification",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/email-verification"),
  },
  {
    path: "/auth/email-verification-2",
    name: "email-verification-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/email-verification-2"),
  },
  {
    path: "/auth/two-step-verification",
    name: "two-step-verification",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/two-step-verification"),
  },
  {
    path: "/auth/two-step-verification-2",
    name: "two-step-verification-2",
    meta: { authRequired: true },
    component: () => import("./views/sample-pages/two-step-verification-2"),
  },
];